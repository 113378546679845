import React from 'react'
import { graphql } from 'gatsby'
import tw, { css, styled } from 'twin.macro'

import { MapPinIcon } from '@heroicons/react/24/outline'
import Container from '../components/Container'
import CardContainer from '../components/CardContainer'

const JobGrid = styled.div`
  ${tw`grid grid-cols-1 gap-4 md:grid-cols-2`}
`

const jazzhr = ({ data }) => {
  // console.log(data)
  const {
    allJazzHr: { edges },
    totalCount: totalJobs,
  } = data

  return (
    <Container variant="wide" tw="py-10 max-w-screen-2xl">
      <h2>Jobs at Umbrella</h2>
      {edges.length ? (
        <JobGrid>
          {edges.map(job => (
            <CardContainer key={job.node.id} tw="pb-5 flex flex-col justify-between">
              <h3 tw="mb-1 text-[20px]">{job.node?.title}</h3>
              <p tw="flex items-center text-sm">
                <MapPinIcon tw="text-purple-400 w-5 h-5 inline-block mr-1" /> {job.node?.city ?? 'Hamilton'}, {job.node.state ?? 'ON'},{' '}
                {job.node?.country_id}
              </p>
              <p>{job.node?.type ? `${job.node.type} Position` : ''}</p>
            </CardContainer>
          ))}
        </JobGrid>
      ) : (
        <p>We don't have any job postings at this time</p>
      )}
    </Container>
  )
}

export const query = graphql`
  query JazzHRJobsQuery {
    allJazzHr(filter: { status: { eq: "Open" } }, sort: { fields: title, order: ASC }) {
      totalCount
      edges {
        node {
          id
          title
          city
          state
          country_id
          status
          type
          original_open_date
          custom_apply_url
        }
      }
    }
  }
`

export default jazzhr
